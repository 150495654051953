import(/* webpackMode: "eager" */ "/vercel/path0/apps/fisiocrem/lib/ui/live-preview/preview-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/fisiocrem/lib/ui/theme.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/fisiocrem/styles/global.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.0_next@14.2.6_react@18.3.1_sass@1.80.7__react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@14.2.6_react@18.3.1_sass@1.80.7__react@18.3.1/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@7.1.4_@sanity+client@6.22.4_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.55._gop6zduhvb2pnvbwd4xtoglpmm/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_babel-plugin-_arttaek6pqv4p7xbyx7fqzpfcu/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_babel-plugin-_arttaek6pqv4p7xbyx7fqzpfcu/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.2_babel-plugin-_arttaek6pqv4p7xbyx7fqzpfcu/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\"}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/mui/theme-registry.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/global.scss");
